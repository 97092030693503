<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.show" />

    <b-row v-else class="justify-content-md-center">
      <b-col md="12">
        <!--  -->
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-row>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">
                      {{ $t("g.isTechEvaluationReportAccepted") }}
                    </h5>
                    <h5 class="mb-0 t_1">
                      {{
                        techEvaluationsReviews.is_tech_evaluation_report_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">
                      {{ $t("g.techEvaluationRejectionReason") }}
                    </h5>
                    <h5 class="mb-0 t_1">
                      {{
                        techEvaluationsReviews.tech_evaluation_rejection_reason
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="ArrowDownCircleIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.submitDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ formatDate(techEvaluationsReviews.submit_date) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- ** -->
        <!--  -->
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12">
                <h4>
                  {{ $t("g.serviceRequest") }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.serviceCode") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ techEvaluationsReviews.service_request.service_code }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="PackageIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.productName") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ techEvaluationsReviews.service_request.prodcut_name }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.status") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{ techEvaluationsReviews.service_request.stauts }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.isReviewAccepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        techEvaluationsReviews.service_request
                          .is_review_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.isClientAccepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        techEvaluationsReviews.service_request
                          .is_client_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.isTechReviewAccepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        techEvaluationsReviews.service_request
                          .is_tech_review_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="HashIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.is_final_review_accepted") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        techEvaluationsReviews.service_request
                          .is_final_review_accepted
                          ? $t("g.accepted")
                          : $t("g.notAccepted")
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- ** -->
        <b-card no-body class="card-developer-meetup">
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12">
                <h4>
                  {{ $t("g.theDates") }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-media no-body class="">
                  <b-media-aside>
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.clientActionDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request
                            .client_action_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.sentForTechDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request
                            .sent_for_tech_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.sentForTechReviewDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request
                            .sent_for_tech_review_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4 mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.reviewActionDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request
                            .review_action_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.techReviewActionDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request
                            .tech_review_action_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.sentForFinalReviewDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request
                            .sent_for_final_review_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.receiveDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request.receive_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.finalReviewActionDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request
                            .final_review_action_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.excpectedReviewEndDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request
                            .excpected_review_end_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.submitDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request.submit_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="4  mt-2">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="35">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ $t("g.closedDate") }}</h5>
                    <h5 class="mb-0 t_1">
                      {{
                        formatDate(
                          techEvaluationsReviews.service_request.closed_date
                        )
                      }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BMedia,
} from "bootstrap-vue";
import Error from "@/views/components/logic/error.vue";
import Loading from "@/views/components/logic/loading.vue";
import dateTimeInFormat from "@/libs/format-date";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCol,
    BMedia,
    Loading,
    Error,
    ContentNotView,
  },
  data() {
    return {
      techEvaluationsReviews: null,
      isLoading: true,
      isError: false,
      formatDate: dateTimeInFormat,
      subPermission: {},
    };
  },
  beforeMount() {
    this.$http
      .get(
        `admin/serviceRequests/${this.$route.params.id}/techEvaluationsReviews/${this.$route.params.techId}`
      )
      .then((res) => {
        this.techEvaluationsReviews = res.data.data;
        
        setTimeout(() => {
          this.isLoading = false;
          this.isError = false;
          store.dispatch("GET_PERMISSION", "techEvaluationsReviews");
          this.subPermission = store.state.permissions.sub;
        }, 1000);
      })
      .catch((err) => {
        this.isLoading = false;
        this.isError = true;

        console.log(err);
      });
  },
};
</script>

<style>
.t_1 {
  margin-top: 0.4rem;
}
</style>
